import {Component, ElementRef, inject, Input, OnInit, output, ViewChild} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Application} from '@application/data';
import {IdType} from '@axiocode/entity';
import {InformationSystemStore} from '@information-system/data';
import {SearchItem, SearchStore} from '@search/data';

import {ToolbarLocalStore} from '../../+state/toolbar-local.store';

@Component({
    selector: 'toolbar-global-search',
    providers: [SearchStore],
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    standalone: false
})
export class GlobalSearchComponent implements OnInit {

    @Input() application?: Application = undefined;
    queryChanged = output<string>();
    @ViewChild('searchFieldHtml', {read: ElementRef}) searchFieldHtml?: ElementRef = undefined;

    searchStore = inject(SearchStore);
    informationSystem = toSignal(inject(InformationSystemStore).selectSelectedEntity$);

    searchField: UntypedFormControl = new UntypedFormControl();
    focusResult = false;
    mouseEnterResult = true;
    selectedIndex = 0;

    constructor(
        private store: ToolbarLocalStore,
        private router: Router,
    ) {
        this.searchStore.setMatch('extended');
        this.searchStore.setLimit(20); // @TODO : voir quel est la meilleure limite, si on en met une
    }

    ngOnInit(): void {
        this.searchField.valueChanges.subscribe(value => {
            this.searchStore.setQuery(value);
            this.queryChanged.emit(value);
        }); // signal way
    }

    refreshSearchState(): void {
        if (!this.focusResult && !this.mouseEnterResult) {
            this.store.setIsSearchOpened(false);
        }
    }

    clearInputField(): void {
        this.searchField.setValue('');
    }

    focusInput(): void {
        this.focusResult = !this.focusResult;
        this.refreshSearchState();
    }

    searchMouseEventIn(): void {
        this.mouseEnterResult = true;
        this.refreshSearchState();
    }

    searchMouseEventOut(): void {
        this.mouseEnterResult = false;
        this.refreshSearchState();
    }

    navigateTo(item: SearchItem): void {
        if (!item.typeClass) {
            return;
        }

        let system, app: { id: IdType };
        switch (item.typeClass) {
            // these are on the IS and not inside of an application:
            case 'actor':
            case 'datamodel':
            case 'glossaryterm':
                system = (item.sourceObject as { informationSystem: { id: IdType } }).informationSystem;
                this.router.navigate(['/', 'is', system.id, item.typeClass, (item.sourceObject as { id: IdType }).id]);
                break;

            default:
                system = (item.sourceObject as { informationSystem: { id: IdType } }).informationSystem;
                app = (item.sourceObject as { application: { id: IdType } }).application;
                this.router.navigate(['/', 'is', system.id, 'application', app.id, item.typeClass, (item.sourceObject as { id: IdType }).id]);
                break;
        }
    }

    openPreview(type: string | undefined, id?: IdType): void {
        if (id && type) {
            this.router.navigate(['', {
                outlets: {drawer: ['preview', type, id]}
            }]);
        }
    }

    onKeyDown(event: KeyboardEvent): void {
        if ('ArrowUp' === event.key) {
            event.preventDefault();
            event.stopImmediatePropagation();

            if (0 === this.selectedIndex) {
                // If we're on top of the list and hit "ArrowUp", focus/show the search field again
                this.searchFieldHtml?.nativeElement.scrollIntoView();
            } else {
                // Select next element and make sure we scroll on it so that it's visible
                this.selectedIndex = Math.max(0, this.selectedIndex - 1);
                document.getElementById('search-result-' + this.selectedIndex)?.scrollIntoView();
            }
        } else if ('ArrowDown' === event.key) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.selectedIndex = Math.min(this.searchStore.resultCount() - 1, this.selectedIndex + 1);
            document.getElementById('search-result-' + this.selectedIndex)?.scrollIntoView();
        } else if ('Enter' === event.key) {
            if (this.searchStore.resultCount() && this.focusResult) {
                const selectedItem = this.searchStore.results()[this.selectedIndex];
                this.navigateTo(selectedItem.item);
            }
        } else if ('Escape' === event.key) {
            this.store.setIsSearchOpened(false);
        }
    }
}
