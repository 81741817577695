import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {UseCase} from '../models/use-case.model';

@Injectable({providedIn: 'root'})
export class UseCaseProvider extends EntityProvider<UseCase> implements OnDestroy {
    /** @ignore */
    private IS: InformationSystem | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<UseCase[]> {
        if (undefined === this.IS) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.IS.id}/usecases`);
    }

    override findOne$(id: IdType): Observable<UseCase> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/usecase/${id}`);
    }

    override create$(data: Partial<UseCase>): Observable<UseCase> {
        return this._create$(`${this.config.apiBaseUrl}/api/usecase`, data);
    }

    override update$(data: Partial<UseCase>, method: 'patch' | 'put'): Observable<UseCase> {
        return this._update$(`${this.config.apiBaseUrl}/api/usecase/${data.id}`, data, method);
    }

    override delete$(data: UseCase): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/usecase/${data.id}`);
    }

    clone$(useCaseId: IdType, modifiedUseCase: Partial<UseCase>): Observable<UseCase> {
        return this._create$(
            `${this.config.apiBaseUrl}/api/informationsystem/${modifiedUseCase.informationSystem?.id}/usecase/${useCaseId}/clone`,
            modifiedUseCase
        );
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        ISStore: InformationSystemStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current IS
            tap(() => this.resetCache()),
            tap(IS => this.IS = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
