import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Hotkeys, UserPreferencesService, UserPreferencesTypes} from '@configuration';

@Component({
    selector: 'ui-save-actions-button',
    template: `
    <button
        mat-raised-button class="mat-primary split-button-left"
        data-cy="save-dialog-button"
        (click)="clicked(lastSave === 'redirect', lastSave === 'addAnother')"
        [disabled]="disabled">
        <span *ngIf="!lastSave || lastSave === 'save'" >{{ 'COMMON.SAVE' | translate }} </span>
        <span data-cy="save-and-see-detail-span" *ngIf="lastSave === 'redirect'">{{ 'COMMON.SAVE_AND_SEE_DETAILS' | translate }} </span>
        <span data-cy="save-and-add-another-span" *ngIf="lastSave === 'addAnother'">{{ 'COMMON.SAVE_AND_ADD_ANOTHER' | translate }} </span>
    </button>

    <button data-cy="save-more-dialog-button" mat-raised-button color="primary" class="split-button-right" [matMenuTriggerFor]="dropdownMenu"
            [disabled]="disabled"
            *ngIf="dropdownDisplay">
        <mat-icon aria-hidden="false" aria-label="Expand icon">expand_more
        </mat-icon>
    </button>

    <mat-menu data-cy="save-more-options-container" #dropdownMenu="matMenu" xPosition="before">
        <a mat-menu-item
            *ngIf="lastSave === 'redirect' || lastSave === 'addAnother' "
            data-cy="save-dialog-button"
            (click)="clicked(false,false)"
            [disabled]="disabled">
            {{ 'COMMON.SAVE' | translate }}
        </a>
        <a mat-menu-item
            data-cy="save-and-see-detail-button"
            *ngIf="lastSave !== 'redirect'"
            (click)="clicked(true,false)"
            [disabled]="disabled">
            {{ 'COMMON.SAVE_AND_SEE_DETAILS' | translate }}
        </a>
        <a mat-menu-item
            *ngIf="lastSave !== 'addAnother'"
            data-cy="save-and-add-another-button"
            (click)="clicked(false,true)"
            [disabled]="disabled">
            {{ 'COMMON.SAVE_AND_ADD_ANOTHER' | translate }}
        </a>
    </mat-menu>
    `,
    standalone: false
})
export class SaveActionsButtonComponent implements OnInit {

    lastSave: string | undefined = undefined;
    hotkeys = Hotkeys;

    @Input() disabled = false;
    @Input() dropdownDisplay = true;
    @Input() set fixedSaveButton(button: 'save' | 'redirect' | 'addAnother') {
        this.lastSave = button;
    }
    @Output() submitted: EventEmitter<{redirect: boolean, another: boolean}> = new EventEmitter<{redirect: boolean; another: boolean}>();

    constructor(
        private userPreferencesService: UserPreferencesService
        // eslint-disable-next-line no-empty-function
    ) {}

    ngOnInit() {
        this.lastSave = this.lastSave ?? this.userPreferencesService.get(UserPreferencesTypes.SAVE) as string;
    }

    clicked(redirect: boolean, another: boolean): void {
        this.submitted.emit({redirect, another});
        this.savePreferences(redirect, another);
    }

    savePreferences(redirect: boolean, another: boolean): void {
        if (redirect) {
            this.userPreferencesService.save(UserPreferencesTypes.SAVE, 'redirect');
        } else if (another) {
            this.userPreferencesService.save(UserPreferencesTypes.SAVE, 'addAnother');
        } else {
            this.userPreferencesService.save(UserPreferencesTypes.SAVE, 'save');
        }
    }

}
