export const environment = {
    production: true,
    env: 'int',
    debug: false,
    release: require('package.json').version,
    apiUrl: 'https://api-int.reqbuilder.com',
    url: 'https://app-int.reqbuilder.com',
    httpCacheTTL: 60000,
    adobeEmbedPdfApiKey: '4c05a08f65f1484d96c7054c8096a7ed',
    googleClientId: '998487747478-n9e35h05j0be4pd75psc0gh3k0g6hoh8.apps.googleusercontent.com',
    optionalModules: [],
    authCookieDomain: 'reqbuilder.com',
    featureFlags: {
        fuzzySearch: true,
    },
};
