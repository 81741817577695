<div class="search-space" (mouseenter)="searchMouseEventIn()" (mouseleave)="searchMouseEventOut()">
    <div>
        <div class="searchbar">
            <mat-form-field #searchFieldHtml disabled="true">
                <!-- <mat-label>{{ 'SEARCH.SEARCH' | translate }}</mat-label>
                <mat-icon matPrefix>search</mat-icon> -->
                <input
                    matInput type="text"
                    id="searchInput"
                    autocomplete="off"
                    [formControl]="searchField"
                    (keydown)="onKeyDown($event)"
                    (focus)="focusInput()"
                    (focusout)="focusInput()">
                <button *ngIf="searchField" matSuffix mat-icon-button aria-label="Clear" (click)="clearInputField()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="search-container"  [hidden]="!focusResult && !mouseEnterResult">
        <div class="overview-content">
            <div
                *ngFor="let result of searchStore.results(); let index = index;"
                [class]="{'overview-item': true, 'selected': index === selectedIndex}"
                [id]="'search-result-' + index">
                <div (click)="openPreview(result.item.typeClass.toString().toLowerCase(), result.item.id)">

                    <div class="overview-title">
                        <div class="search-name">
                            <span class="code-prefix-search" *ngIf="result.item.codeAndPrefix"
                                [innerHTML]="result.item.codeAndPrefix + ' - ' | highlight: searchStore.query()"></span>
                            <h3 [innerHTML]="result.item.name | highlight: searchStore.query()"></h3>
                        </div>
                    </div>

                    <div class="description">
                        <div class="type">{{ "TYPE_CLASS."+result.item.typeClass | uppercase | translate }} </div>
                        <div [innerHTML]="result.item.searchStr | noHtml | fzfHighlight: result"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
