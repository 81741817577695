import {Injectable} from '@angular/core';
import {RouteBuilder} from '@axiocode/routing';

@Injectable({providedIn: 'any'})
export class DiscussionRoutesForIS extends RouteBuilder {
    list = () => this.url('list');
}

@Injectable({providedIn: 'any'})
export class DiscussionRoutesForApplication extends RouteBuilder {
    list = () => this.url('list');
}
