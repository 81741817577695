import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ErrorHandler, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {ConfigurationStore} from '@configuration';
import {DataModelStore} from '@data-model/data';
import {GlossaryTerm, GlossaryTermLocalStore, GlossaryTermStore, Term, formatAPICreate, formatAPIUpdate} from '@glossary-term/data';
import {InformationSystem} from '@information-system/data';
import {MentionConversionService} from '@mention/data';
import {filterOnName, firstOrNull, modelsEqualityById} from '@utils';
import * as CustomEditor from 'managician-ckeditor';
import {BehaviorSubject, delay, Subject, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {GlossaryTermType} from '../../forms/glossary-term.type';

@Component({
    selector: 'glossary-term-form',
    templateUrl: './glossary-term-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GlossaryTermLocalStore],
    standalone: false
})
export class GlossaryTermFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Output() submitted: Subject<GlossaryTerm> = new Subject<GlossaryTerm>();
    @Output() isInvalid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    @Input() informationSystem?: InformationSystem = undefined;
    @Input() glossaryTerm?: GlossaryTerm = undefined;
    @Input() form!: FormGroup;

    nextCodeAvailable$ = this.store.selectNextCodeAvailable$.pipe(
        delay(1),
        tap(value => {
            if (value && !this.glossaryTerm?.id) {
                this.form.patchValue({code: value});
            }
        })
    );

    private subs = new SubSink();

    Editor = CustomEditor;
    isCreateMode = false;
    compare = modelsEqualityById;
    filter = filterOnName;
    prefix = '';
    separatorKeysCodes: number[] = [ENTER, COMMA];
    plurals: Term[] = [];
    synonyms: Term[] = [];
    formType: GlossaryTermType = new GlossaryTermType();

    constructor(
        private store: GlossaryTermStore,
        private localStore: GlossaryTermLocalStore,
        private dataModelStore: DataModelStore,
        private configurationStore: ConfigurationStore,
        @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
        private mentionConversionService: MentionConversionService,
        private cd: ChangeDetectorRef,
    ) {
        this.form = this.formType.getForm();
    }

    /** @ignore */
    ngOnInit(): void {
        this.store.findAll();
        this.configurationStore.load();

        this.subs.sink = this.nextCodeAvailable$.subscribe();

        this.subs.sink = this.store.updated.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);
            }
        });

        this.subs.sink = this.store.created.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);

                // If a data model has been generated from the term, we need to refresh the data model store.
                this.dataModelStore.reset(); // Ensure we don't have HTTP cache
                this.dataModelStore.findAll();
            }
        });

        this.subs.sink = this.store.error.subscribe(error => {
            this.localStore.changeSaveState(false);
            let apiFormError = this.errorHandler.handleError(error);
            if (apiFormError?.formErrors && this.form) {
                this.errorHandler.applyFormErrors(apiFormError.formErrors, this.form);
                this.cd.detectChanges();
            }
        });

        if (this.glossaryTerm?.id) {
            this.setValue(this.glossaryTerm);
            this.isCreateMode = false;
        } else {
            this.isCreateMode = true;
        }

        this.subs.sink = this.configurationStore.selectConfigurations$.pipe(
            tap(value => this.prefix = value?.glossaryTermCodePrefix as string)
        ).subscribe();
    }

    /** @ignore */
    ngAfterViewInit(): void {
        // Invalid changes
        this.subs.sink = this.form.statusChanges.subscribe(() => this.isInvalid.next(this.form.invalid));
    }

    /** @ignore */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['glossaryTerm'].firstChange && changes['glossaryTerm'].currentValue && 'edition' !== this.localStore.state().versionableState) {
            this.setValue(changes['glossaryTerm'].currentValue);
        }
        if (!this.localStore.state().force && changes['glossaryTerm'].previousValue && changes['glossaryTerm'].currentValue?.id !== changes['glossaryTerm'].previousValue?.id) {
            // If the new entity is different than the previous one, we change the state to "view".
            if (changes['glossaryTerm'].previousValue && changes['glossaryTerm'].currentValue?.id !== changes['glossaryTerm'].previousValue?.id) {
                this.localStore.changeVersionableState('view');
                this.plurals = [];
                this.synonyms = [];
            }
        }
    }

    /** @ignore */
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    validate(): void {
        if (this.form.valid) {
            const data = this.getValue();

            if (this.isCreateMode) {
                this.store.create(formatAPICreate(data));
            } else {
                this.store.update({data: formatAPIUpdate(data)});
            }
            this.localStore.changeSaveState(true);
        }
    }

    setValue(glossaryTerm: GlossaryTerm): void {
        this.form.patchValue(glossaryTerm);
        this.mentionConversionService.convertDescriptionMention(glossaryTerm.description).pipe(
            take(1), // without that, the description is reset everytime the store has new data
        ).subscribe(
            value => {
                if (!this.form.get('description')?.touched) {
                    this.form.patchValue({description: value});
                }
            }
        );
        this.synonyms = [];
        this.plurals = [];
        this.form.getRawValue().synonyms.forEach((term: Term) => this.synonyms.push(term));
        this.form.getRawValue().plurals.forEach((term: Term) => this.plurals.push(term));
    }

    getValue(): GlossaryTerm {
        let data = this.form.getRawValue();
        data.synonyms = this.synonyms;
        data.plurals = this.plurals;
        if (this.glossaryTerm) {
            data.id = this.glossaryTerm.id;
            if (this.isCreateMode) {
                data = {
                    ...this.glossaryTerm,
                    ...data,
                    synonyms: this.glossaryTerm.synonyms.map(term => ({
                        word: term.word,
                    })),
                    plurals: this.glossaryTerm.plurals.map(term => ({
                        word: term.word,
                    })),
                };
            }
        }
        if (this.informationSystem) {
            data.informationSystem = this.informationSystem;
        }

        return data;
    }

    removeSynonym(term: Term): void {
        const index = this.synonyms.indexOf(term);
        if (index >= 0) {
            this.synonyms.splice(index, 1);
            this.form.get('synonyms')?.updateValueAndValidity();
        }
    }

    removePlural(term: Term): void {
        const index = this.plurals.indexOf(term);
        if (index >= 0) {
            this.plurals.splice(index, 1);
            this.form.get('plurals')?.updateValueAndValidity();
        }
    }

    addSynonym(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value && this.form.get('synonymControl')?.valid) {
            const index = this.synonyms.findIndex(term => term.word === value);
            if (-1 === index) {
                let newTerm: Term = {word: value};
                this.synonyms.push(newTerm);
                this.form.get('synonyms')?.updateValueAndValidity();
            }
            // Clear the input value
            event.chipInput.clear();
        }
    }

    addPlural(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value && this.form.get('pluralControl')?.valid) {
            const index = this.plurals.findIndex(term => term.word === value);
            if (-1 === index) {
                let newTerm: Term = {word: value};
                this.plurals.push(newTerm);
                this.form.get('plurals')?.updateValueAndValidity();
            }
        }

        // Clear the input value
        event.chipInput.clear();
    }
}
